<template>
  <section class="hero-stack wf-section">
    <div class="container-7">
      <div class="hero-wrapper-two custom-loader-wrapper notFound-wrapper">
        <div class="notFound-div">
          <h1 style="margin: 0">Page Not Found</h1>
          <p>
            The page you're looking for does not exist or have been temporarily
            unavailable
          </p>
          <a href="/" class="button-primary w-button">Go To Homepage</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
};
</script>
<style>
.notFound-wrapper {
  min-height: 80vh;
}
.notFound-div {
  text-align: center;
}
.notFound-div a {
  margin-top: 20px;
}
.notFound-div p {
  margin-top: 20px;
  margin-bottom: 0;
  color: #333;
}
</style>
