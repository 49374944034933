<template>
  <section id="contactSection" class="contactsection wf-section">
    <div class="container-5">
      <!-- <div class="contactheadingdiv">
        <h3 class="numberheading">
          Lets Us Help You! Call Now:
          <span class="text-span-3">0308-2999724</span>
        </h3>
        <h2 class="contactheading">Leave A Message</h2>
    </div> -->
      <div class="contact-div">
        <h1 class="centered-heading">
          <span class="highlightheading">Leave </span>A Message
        </h1>
      </div>
      <div class="footer-form-two w-form">
        <form
          id="wf-form-Footer-Form-Two"
          name="wf-form-Footer-Form-Two"
          data-name="Footer Form Two"
          method="get"
          class="footer-form-container-two"
          @submit.prevent="formSubmit"
        >
          <div class="footer-form-block-two">
            <div class="form-upper">
              <input
                type="text"
                class="footer-form-input w-input"
                maxlength="256"
                name="Footer-Email-Two"
                data-name="Footer Email Two"
                aria-label="Enter your email"
                placeholder="Enter your name"
                id="Footer-Email-Two"
                required=""
                v-model="mailObj.name"
              /><input
                type="email"
                class="footer-form-input w-input"
                maxlength="256"
                name="Footer-Email-Two-2"
                data-name="Footer Email Two 2"
                aria-label="Enter your email"
                placeholder="Enter your email"
                id="Footer-Email-Two-2"
                required=""
                v-model="mailObj.email"
              />
            </div>
            <div class="form-lower">
              <textarea
                class="footer-form-input message-input w-input"
                name="message"
                placeholder="Enter your message"
                rows="8"
                cols="50"
                v-model="mailObj.message"
              ></textarea>
            </div>
            <div class="form-button-block">
              <button
                type="submit"
                class="button-primary w-button"
                :disabled="request"
              >
                <div
                  class="custom-mini-loader"
                  v-if="request"
                  style="margin: auto"
                ></div>
                <span v-else>Send Message</span>
              </button>
              <!-- <input
                type="submit"
                class="button-primary w-button"
                value="Send Message"
                :disabled="request"
              /> -->
              <a
                href="https://wa.me/+923082999724"
                target="_blank"
                class="button-primary w-button"
                ><img
                  src="https://cdn.cloudious.net/file-1676700040613-583826971.svg"
                  alt="Whatsapp"
                  loading="lazy"
                  width="25"
                />
                0308-2999724</a
              >
              <!-- <a
                class="contact-details"
                href="https://wa.me/+923082999724"
                target="_blank"
              >
                <img
                  src="https://cdn.cloudious.net/file-1676700040613-583826971.svg"
                  alt="Whatsapp"
                  loading="lazy"
                />
                0308-2999724
              </a> -->
            </div>
            <!-- <a href="#" class="button-primary w-button">submit</a> -->
            <!-- contactsubmit -->
          </div>
        </form>
        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
  name: "Contact",
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      request: false,
      // receiver: "aseefjawaid123@gmail.com",
      receiver: "aafiamovement.contact@gmail.com",
      mailObj: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    formSubmit() {
      // console.log(this.mailObj);
      this.request = true;

      const config = {
        method: "post",
        url: "https://mail.appick.io/cloudious",
        data: {
          emailTo: this.receiver,
          subject: "Aafia Movement",
          text: `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nMessage: ${this.mailObj.message}`,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            // close the popup
            this.toast.success("Message sent successfully!");
            this.mailObj = {
              name: "",
              email: "",
              message: "",
            };

            this.request = false;
          } else {
            this.toast.error("Something went wrong!");
            this.request = false;
          }
        })
        .catch((err) => {
          // console.log(err);
          this.toast.error("Something went wrong!");
          this.request = false;
        });
    },
  },
};
</script>
<style></style>
