<template>
  <aside class="footer-light wf-section">
    <div class="container-3">
      <div class="footer-wrapper-two">
        <div class="footer-block-two">
          <div class="footer-title">News</div>

          <a
            v-for="item in this.$store.state.news.slice(0, 5)"
            :key="item"
            :href="'/single-news?id=' + item.id"
            class="footerlinkblock w-inline-block"
          >
            <div>{{ item.title }}</div>
          </a>

          <!-- <a href="/single-news" class="footerlinkblock w-inline-block">
            <div>Dr Aafia’s lawyer urges more efforts for her return</div>
          </a>
           -->
        </div>
        <div class="footer-block-two">
          <div class="footer-title">Articles</div>
          <a
            v-for="item in this.$store.state.articles.slice(0, 5)"
            :key="item"
            :href="'/single-article?id=' + item.id"
            class="footerlinkblock w-inline-block"
          >
            <div>{{ item.title }}</div>
          </a>
          <!-- <a href="#" class="footerlinkblock w-inline-block">
            <div>Dr Aafia’s lawyer urges more efforts for her return</div>
          </a>
         -->
        </div>
        <div class="footer-block-two">
          <div class="footer-title">Contact</div>
          <a
            class="footerlinkblock w-inline-block"
            href="https://wa.me/+923082999724"
            target="_blank"
          >
            <div>
              <img src="images/icons8-whatsapp.svg" loading="lazy" alt="" />
              0308-2999724
            </div>
          </a>
          <a
            class="footerlinkblock w-inline-block"
            href="mailto:aafiamovement.contact@gmail.com"
          >
            <div>
              <img src="images/icons8-envelope-48.png" loading="lazy" alt="" />
              aafiamovement.contact@gmail.com
            </div>
          </a>
          <div class="footer-social-block-two">
            <a
              href="https://twitter.com/Aafiamovement"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <!-- <img src="images/icons8-twitter-1.svg" loading="lazy" alt="" /> -->
              <svg
                width="18"
                height="18"
                viewBox="0 0 21 17"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.862 4.84c0-.19-.01-.369-.018-.557A8.445 8.445 0 0 0 21 2.1a8.609 8.609 0 0 1-2.475.638A4.188 4.188 0 0 0 20.419.41a8.522 8.522 0 0 1-2.734.988A4.39 4.39 0 0 0 14.543 0a4.236 4.236 0 0 0-4.312 4.243c0 .335.035.661.113.979C6.76 5.002 3.584 3.245 1.462.59a4.295 4.295 0 0 0-.587 2.174c0 1.51.763 2.851 1.921 3.636A4.313 4.313 0 0 1 .84 5.835v.059a4.379 4.379 0 0 0 3.463 4.282 4.34 4.34 0 0 1-1.134.146 4.405 4.405 0 0 1-.814-.09 4.324 4.324 0 0 0 4.025 3.032A8.619 8.619 0 0 1 0 15.038 12.048 12.048 0 0 0 6.606 17c7.92 0 12.256-6.514 12.256-12.16"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/aafiamovementofficial/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <!-- <img src="images/icons8-instagram.svg" loading="lazy" alt="" /> -->
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="currentColor" fill-rule="evenodd">
                  <path
                    d="M18.225 15.204c0 2.17-1.68 3.932-3.744 3.932H5.519c-2.064 0-3.744-1.762-3.744-3.932v-9.41c0-2.168 1.68-3.93 3.744-3.93h8.962c2.064 0 3.744 1.762 3.744 3.93v9.41zM14.481 0H5.519C2.475 0 0 2.599 0 5.794v9.41C0 18.401 2.475 21 5.52 21h8.96c3.045 0 5.52-2.599 5.52-5.796v-9.41C20 2.599 17.525 0 14.48 0z"
                  ></path>
                  <path
                    d="M10 14.931a3.936 3.936 0 0 1-3.933-3.932A3.938 3.938 0 0 1 10 7.064a3.937 3.937 0 0 1 3.933 3.935A3.936 3.936 0 0 1 10 14.93M10 5c-3.308 0-6 2.69-6 5.999A6.008 6.008 0 0 0 10 17c3.308 0 6-2.694 6-6.001A6.006 6.006 0 0 0 10 5M14.501 3c-.394 0-.783.158-1.06.438-.278.28-.441.666-.441 1.06 0 .395.163.782.44 1.062.278.277.667.44 1.061.44.395 0 .781-.163 1.059-.44.28-.28.44-.667.44-1.061A1.498 1.498 0 0 0 14.501 3"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              href="https://web.facebook.com/aafiamovement.official/"
              target="_blank"
              class="footer-social-link w-inline-block"
            >
              <!-- <img src="images/icons8-facebook-1.svg" loading="lazy" alt="" /> -->
              <svg
                width="18"
                height="18"
                viewBox="0 0 11 23"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.271 23V11.488h3.892l.619-3.818h-4.51V5.756c0-1 .334-1.947 1.796-1.947H11V0H6.843C3.348 0 2.396 2.251 2.396 5.36v2.31H0v3.818h2.396V23H6.27z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <a href="/" class="footer-brand w-inline-block"
            ><img
              src="images/logo-2.png"
              srcset="images/logo-2.png 500w, images/logo-2.png 578w"
              loading="lazy"
              sizes="(max-width: 479px) 40vw, 90px"
              alt=""
          /></a>

          <!-- <div class="footer-title">Case Videos</div>
          <a
            v-for="(item, ind) in videos"
            :key="item"
            :href="item.link"
            class="footerlinkblock w-inline-block"
            :id="'footer-video-' + (ind + 1)"
          >
            <div>{{ item.title }}</div>
          </a> -->
          <!-- <a
            href="https://www.youtube.com/watch?v=Rg6L4yUlnbs"
            target="_blank"
            class="footerlinkblock w-inline-block"
          >
            <div>Where is Afia siddiqui ?</div>
          </a>
           -->
        </div>

        <div class="footer-block-two hideblock">
          <div class="footer-title">Urgent Causes</div>
          <div class="footer-social-block-two">
            <a href="#" class="footer-social-link w-inline-block"
              ><img src="images/thumb1.jpg" loading="lazy" alt=""
            /></a>
            <div>
              <div class="footertext">Contribute For Food hunger</div>
              <div class="footertextdate">03 March 2023</div>
            </div>
          </div>
          <div class="footer-social-block-two">
            <a href="#" class="footer-social-link w-inline-block"
              ><img src="images/thumb2.jpg" loading="lazy" alt=""
            /></a>
            <div>
              <div class="footertext">Contribute For Food hunger</div>
              <div class="footertextdate">03 March 2023</div>
            </div>
          </div>
          <div class="footer-social-block-two">
            <a href="#" class="footer-social-link w-inline-block"
              ><img src="images/thumb3.jpg" loading="lazy" alt=""
            /></a>
            <div>
              <div class="footertext">Contribute For Food hunger</div>
              <div class="footertextdate">03 March 2023</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom footer-bordered hideblock">
        <a href="/" class="footer-brand w-inline-block"
          ><img
            src="images/Aafia Movenment-01.png"
            srcset="images/Aafia Movenment-01.png 500w, images/Aafia Movenment-01.png 578w"
            loading="lazy"
            sizes="(max-width: 479px) 40vw, 90px"
            alt=""
        /></a>
        <!-- srcset="images/logo-p-500.jpg 500w, images/logo.jpg 578w" -->

        <div class="footer-social-block-two">
          <a
            href="https://twitter.com/Aafiamovement"
            target="_blank"
            class="footer-social-link w-inline-block"
          >
            <!-- <img src="images/icons8-twitter-1.svg" loading="lazy" alt="" /> -->
            <svg
              width="18"
              height="18"
              viewBox="0 0 21 17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.862 4.84c0-.19-.01-.369-.018-.557A8.445 8.445 0 0 0 21 2.1a8.609 8.609 0 0 1-2.475.638A4.188 4.188 0 0 0 20.419.41a8.522 8.522 0 0 1-2.734.988A4.39 4.39 0 0 0 14.543 0a4.236 4.236 0 0 0-4.312 4.243c0 .335.035.661.113.979C6.76 5.002 3.584 3.245 1.462.59a4.295 4.295 0 0 0-.587 2.174c0 1.51.763 2.851 1.921 3.636A4.313 4.313 0 0 1 .84 5.835v.059a4.379 4.379 0 0 0 3.463 4.282 4.34 4.34 0 0 1-1.134.146 4.405 4.405 0 0 1-.814-.09 4.324 4.324 0 0 0 4.025 3.032A8.619 8.619 0 0 1 0 15.038 12.048 12.048 0 0 0 6.606 17c7.92 0 12.256-6.514 12.256-12.16"
                fill="currentColor"
                fill-rule="evenodd"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/aafiamovementofficial/"
            target="_blank"
            class="footer-social-link w-inline-block"
          >
            <!-- <img src="images/icons8-instagram.svg" loading="lazy" alt="" /> -->
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="currentColor" fill-rule="evenodd">
                <path
                  d="M18.225 15.204c0 2.17-1.68 3.932-3.744 3.932H5.519c-2.064 0-3.744-1.762-3.744-3.932v-9.41c0-2.168 1.68-3.93 3.744-3.93h8.962c2.064 0 3.744 1.762 3.744 3.93v9.41zM14.481 0H5.519C2.475 0 0 2.599 0 5.794v9.41C0 18.401 2.475 21 5.52 21h8.96c3.045 0 5.52-2.599 5.52-5.796v-9.41C20 2.599 17.525 0 14.48 0z"
                ></path>
                <path
                  d="M10 14.931a3.936 3.936 0 0 1-3.933-3.932A3.938 3.938 0 0 1 10 7.064a3.937 3.937 0 0 1 3.933 3.935A3.936 3.936 0 0 1 10 14.93M10 5c-3.308 0-6 2.69-6 5.999A6.008 6.008 0 0 0 10 17c3.308 0 6-2.694 6-6.001A6.006 6.006 0 0 0 10 5M14.501 3c-.394 0-.783.158-1.06.438-.278.28-.441.666-.441 1.06 0 .395.163.782.44 1.062.278.277.667.44 1.061.44.395 0 .781-.163 1.059-.44.28-.28.44-.667.44-1.061A1.498 1.498 0 0 0 14.501 3"
                ></path>
              </g>
            </svg>
          </a>
          <a
            href="https://web.facebook.com/aafiamovement.official/"
            target="_blank"
            class="footer-social-link w-inline-block"
          >
            <!-- <img src="images/icons8-facebook-1.svg" loading="lazy" alt="" /> -->
            <svg
              width="18"
              height="18"
              viewBox="0 0 11 23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.271 23V11.488h3.892l.619-3.818h-4.51V5.756c0-1 .334-1.947 1.796-1.947H11V0H6.843C3.348 0 2.396 2.251 2.396 5.36v2.31H0v3.818h2.396V23H6.27z"
                fill="currentColor"
                fill-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
        <div class="pagelinkdiv">
          <a href="/articles" class="pagelinks w-inline-block">Articles</a>
          <a href="/documents" class="pagelinks w-inline-block">Documents</a>
          <a href="/news" class="pagelinks w-inline-block">News</a>
          <a href="/contact" class="pagelinks lastlink w-inline-block"
            >Contact Us</a
          >
        </div>

        <div class="footer-block-two hideblock">
          <div class="footer-social-block-two">
            <a href="#" class="footer-social-link w-inline-block"
              ><img src="images/icons8-whatsapp.svg" loading="lazy" alt=""
            /></a>
            <div class="footertext">0308-2999724</div>
          </div>
          <div class="footer-social-block-two textadjust">
            <a href="#" class="footer-social-link w-inline-block"
              ><img src="images/icons8-envelope-48.png" loading="lazy" alt=""
            /></a>
            <div class="footertext">aafiamovement.contact@gmail.com</div>
          </div>
        </div>
      </div>
      <div class="footer-bottom" style="border-top: 1px solid gray">
        <div class="footer-copyright">
          © {{ year }} Aafia Movement. All rights reserved
        </div>
      </div>
    </div>
  </aside>
  <div
    class="back_to_top"
    :style="{ display: scrollPosition > 600 ? 'block' : 'none' }"
    @click="scrollTop"
  >
    <img
      width="30"
      height="30"
      src="https://img.icons8.com/ios/50/FFFFFF/send-letter--v2.png"
      alt="to top"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  data() {
    return {
      scrollPosition: null,
      year: new Date().getFullYear(),

      news: this.$store.state.news,
      // news: [
      //   {
      //     title: "Dr Aafia’s lawyer urges more efforts for her return",
      //   },
      //   {
      //     title: "Dr Aafia’s lawyer urges more efforts for her return",
      //   },
      //   {
      //     title: "Dr Aafia’s lawyer urges more efforts for her return",
      //   },
      // ],
      articles: this.$store.state.articles,
      // articles: [
      //   {
      //     title: "Dr Aafia’s lawyer urges more efforts for her return",
      //   },
      //   {
      //     title: "Dr Aafia’s lawyer urges more efforts for her return",
      //   },
      //   {
      //     title: "Dr Aafia’s lawyer urges more efforts for her return",
      //   },
      //   {
      //     title: "Dr Aafia’s lawyer urges more efforts for her return",
      //   },
      // ],
      videos: [
        {
          title: "Aafia Siddiqui's plight: 18 Years of Injustice",
          link: "https://www.youtube.com/watch?v=YvAGpWds8D8",
        },
        {
          title: "Where is Afia siddiqui ?",
          link: "https://www.youtube.com/watch?v=Rg6L4yUlnbs",
        },
        {
          title: "In Search of Prisoner 650 Part 1",
          link: "https://www.youtube.com/watch?v=TxHJ0IyKZ2Q",
        },
        {
          title: "My Sister, Aafia | Trailer",
          link: "https://www.youtube.com/watch?v=Dr_qzvyUwqc",
        },
        {
          title: "Aafia Siddiqui: Suffering from Psychological Trauma",
          link: "https://www.youtube.com/watch?v=6UJTswEmR-c",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollTop() {
      window.scrollTo({ top: 10, behavior: "smooth" });
    },
  },
};
</script>
