<template>
  <section class="team-circles-2 wf-section">
    <div class="container-6">
      <div v-if="loading" class="custom-loader-wrapper">
        <div class="custom-loader"></div>
      </div>
      <div v-else class="team-grid-2">
        <div
          v-for="data in items"
          :key="data.id"
          id="w-node-_944ed45d-0b36-0fe2-68a3-da8baa79c596-5f1c185e"
          class="team-card-2"
        >
          <a :href="'/single-news?id=' + data.id" class="card-wrapper">
            <img :src="data.image" loading="lazy" alt="news" class="team-member-image-2" />
            <div class="newdetailsdiv">
              <div class="team-member-name-2 newslink">
                {{ data.title }}
                <!-- <a :href="'/single-news?id=' + data.id" class="newslink">
                {{ data.title }}</a
              > -->
              </div>
              <div v-html="data.content" class="team-member-position-2"></div>
              <p class="newsdate">
                {{
                  new Date(data.date).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
              </p>
            </div>
          </a>
        </div>
        <!-- 
        <div
          id="w-node-_944ed45d-0b36-0fe2-68a3-da8baa79c596-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/1_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="/single-news" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_16fe75e7-251c-c0e5-e339-559f6ca11bf5-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/2_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="/single-news" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_536f325e-061c-5f12-5d4e-e147bfe5f28a-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/3_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="/single-news" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.<strong><br /></strong>
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_13b968d9-ded0-7a65-5527-d942884a6e6a-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/1_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="/single-news" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_96dac795-33c4-3678-36e5-ccbee9e267c1-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/2_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="/single-news" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_1eeb25ca-594e-5845-355b-9c46558f7f91-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/3_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="/single-news" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.<strong><br /></strong>
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
         -->
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "News",
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;

      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/News",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.items = response.data.data;
          this.items.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          this.$store.commit("setNews", this.items);

          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style></style>
