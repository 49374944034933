<template>
  <section class="hero-stack wf-section">
    <div class="container-7">
      <div v-if="loading" class="hero-wrapper-two custom-loader-wrapper">
        <div class="custom-loader"></div>
      </div>

      <div class="hero-wrapper-two" v-else>
        <img v-for="item in content" :key="item" :src="item" loading="lazy" alt="" class="event-image" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SingleArticle",
  data() {
    return {
      loading: false,
      content: ["images/events/event.jpeg"],
    };
  },
  created() {},
  methods: {},
};
</script>
<style></style>
