import { createStore } from "vuex";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  plugins: [vuexLocal.plugin],
  state: {
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbkBhYWZpYS5jb20iLCJqdGkiOiJkNjY3MGJjMS1kMTMxLTRmM2UtODE4Zi02MGM5ZjZjODNkMzUiLCJleHAiOjE3MzI5NjE3MjIsImlzcyI6Im15c2tvb2wuYXBwIiwiYXVkIjoibXlza29vbC5hcHAifQ.IMsuixsNhiQuhJBDpSMloZfzLfO6gK8Cb9LoEBEeZUM",
    articles: [],
    news: [],
    slides: [],
  },
  getters: {},
  mutations: {
    setArticles(state, payload) {
      state.articles = payload;
    },
    setNews(state, payload) {
      state.news = payload;
    },
    setSlides(state, payload) {
      state.slides = payload;
    },
  },
  actions: {},
  modules: {},
});
