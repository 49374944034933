<template>
  <section class="team-circles-2 wf-section">
    <div class="container-6">
      <div class="team-grid-2">
        <div
          id="w-node-_944ed45d-0b36-0fe2-68a3-da8baa79c596-5f1c185e"
          class="team-card-2"
          v-for="item in docs"
          :key="item"
        >
          <a :href="item.link" target="_blank" class="card-wrapper">
            <img :src="item.image ? item.image : 'images/1_1.jpg'" loading="lazy" alt="" class="team-member-image-2" />
            <div class="newdetailsdiv">
              <div class="team-member-name-2 newslink">
                {{ item.title }}
                <!-- <a :href="item.link" target="_blank" class="newslink">{{
                item.title
              }}</a> -->
              </div>
              <div class="team-member-position-2">
                {{ item.about }}
              </div>
              <!-- <p class="newsdate">
                {{
                  new Date(item.date).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
              </p> -->
            </div>
          </a>
        </div>
        <!-- 
        <div
          id="w-node-_944ed45d-0b36-0fe2-68a3-da8baa79c596-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/1_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="#" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_16fe75e7-251c-c0e5-e339-559f6ca11bf5-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/2_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="#" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_536f325e-061c-5f12-5d4e-e147bfe5f28a-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/3_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="#" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.<strong><br /></strong>
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_13b968d9-ded0-7a65-5527-d942884a6e6a-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/1_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="#" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_96dac795-33c4-3678-36e5-ccbee9e267c1-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/2_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="#" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
        <div
          id="w-node-_1eeb25ca-594e-5845-355b-9c46558f7f91-5f1c185e"
          class="team-card-2"
        >
          <img
            src="images/3_1.jpg"
            loading="lazy"
            alt=""
            class="team-member-image-2"
          />
          <div class="newdetailsdiv">
            <div class="team-member-name-2">
              <a href="#" class="newslink"
                >Charity for needy People, make a difference in their lives</a
              >
            </div>
            <div class="team-member-position-2">
              We were established as a EverCare ond charity in 1990 and began to
              operate and some verition.<strong><br /></strong>
            </div>
            <p class="newsdate">15 May 23</p>
          </div>
        </div>
         -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Documents",
  data() {
    return {
      docs: [
        {
          title: "Aafia - Cage",
          link: "https://cdn.cloudious.net/file-1680590739404-523697500.pdf",
          image: "images/Untitled design(2).png",
          about: "Dr. Afia Siddiqui, a case of lies and inconsistencies",
        },
        {
          title: "Aafia-Movement Mission Statement",
          link: "https://cdn.cloudious.net/file-1680592416782-51880220.pdf",
          image: "images/aafia-mission.jpg",
          about: "The Aafia Movement is an umbrella organization which includes everybody and every organization.",
        },
        {
          title: "True Facesheet",
          link: "https://cdn.cloudious.net/file-1680592556841-934946671.pdf",
          image: "images/facts-1.png",
          about: "These are the True facts about Dr. Aafia Siddiqui.",
        },
        {
          title: "Mushahid Hussan Senate Report",
          link: "https://cdn.cloudious.net/file-1680592746791-736704285.pdf",
          image: "images/Untitled design(1).png",
          about:
            "Report of the Standing Committee on Foreign Affairs with Dr. Afia Siddiqui on 7th October 2008 at Texas, USA.",
        },
        {
          title: "Who is Aafia?",
          link: "https://cdn.cloudious.net/file-1689692013598-226105485.pdf",
          image: "images/afia-siddiqui(1).jpg",
          about: "Let’s decode the truth about Dr. Aafia Siddiqui’s Case.",
        },
        {
          title: "Press Release",
          link: "https://cdn.cloudious.net/file-1697809756465-259184404.jpg",
          image: "https://cdn.cloudious.net/file-1692184469118-262790337.jpg",
          about: "IHC Adjourns Aafia Siddiqui’s Case till Nov 17",
        },
        {
          title: "Remembering the Children of Gaza",
          link: "https://cdn.cloudious.net/file-1701341080115-151892014.jpg",
          image: "https://cdn.cloudious.net/file-1701340205777-827965176.jpg",
          about:
            "On the Occasion of World Children's Day, Remembering the Children of Gaza is Essential, Urges Dr. Fowzia Siddiqui",
        },
        {
          title: "Dr. Fowzia meets Dr. Aafia",
          link: "https://cdn.cloudious.net/file-1701875120467-663905374.pdf",
          image: "https://cdn.cloudious.net/file-1701874907567-644056277.jpg",
          about: "Fowzia meets Aafia in prison, expresses concern on her deteriorating health",
        },
        {
          title: "Islamabad High Court hearing",
          link: "https://cdn.cloudious.net/file-1706188518979-957802156.doc", //"https://cdn.cloudious.net/file-1705915810008-926072308.jpg",
          image: "https://cdn.cloudious.net/file-1705915868272-301620461.jpg",
          about: "IHC asks some serious questions",
        },
        {
          title: "Dr. Aafia Siddiqui Conference, Islamabad",
          link: "https://cdn.cloudious.net/file-1706188644893-458676720.doc", //"https://cdn.cloudious.net/file-1706081570158-868170192.jpg",
          image: "https://cdn.cloudious.net/file-1706081705738-727612031.jpg",
          about: "Govt not using available legal, political options for Aafia release: Dr Fowzia",
        },
      ],
    };
  },
};
</script>
<style></style>
