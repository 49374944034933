<template>
  <!-- <span class="highlightheading">Case</span>  -->

  <section class="team-circles-2 wf-section" style="background-color: #f5f7fa">
    <div class="container-2">
      <div
        v-for="(item, ind) in videoData"
        :key="ind"
        style="margin-bottom: 30px"
      >
        <h1 class="donateheading" style="font-weight: 400">{{ item.group }}</h1>
        <Splide
          class="team-slider-wrapper"
          :options="{
            // rewind: false,
            omitEnd: true,
            focus: 0,
            gap: 10,
            perPage: 3,
            perMove: 1,
            type: 'slide',
            arrows: true,
            breakpoints: {
              991: {
                perPage: 2,
              },
              479: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(vid, index) in item.items" :key="index">
            <div class="team-slide-wrapper">
              <a
                href="#"
                class="videolightbox w-inline-block w-lightbox"
                @click="setBox()"
              >
                <img
                  :src="vid.thumbnailUrl"
                  loading="lazy"
                  alt=""
                  class="videothumbnail"
                  :id="`video-${ind}-${index}`"
                />
                <div class="playIcon">
                  <img
                    src="images/play-button.png"
                    loading="lazy"
                    alt="play"
                    style="width: 60px"
                  />
                </div>
              </a>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  </section>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

export default {
  name: "Playlist",
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      videoData: [
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=-wbvNCsZP9g?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=-wbvNCsZP9g?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/-wbvNCsZP9g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F-wbvNCsZP9g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D-wbvNCsZP9g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F-wbvNCsZP9g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYvAGpWds8D8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYvAGpWds8D8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYvAGpWds8D8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=7fMd_2rwVuw?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=7fMd_2rwVuw?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/7fMd_2rwVuw/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7fMd_2rwVuw%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7fMd_2rwVuw&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F7fMd_2rwVuw%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Explainer",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vnItnUz8B-k/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvnItnUz8B-k%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvnItnUz8B-k&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvnItnUz8B-k%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=CLk1UD5P3-U?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=CLk1UD5P3-U?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/CLk1UD5P3-U/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FCLk1UD5P3-U%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DCLk1UD5P3-U&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FCLk1UD5P3-U%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vmaM7T1AQDU/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvmaM7T1AQDU%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvmaM7T1AQDU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvmaM7T1AQDU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/zp7LOtD9wyE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fzp7LOtD9wyE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dzp7LOtD9wyE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fzp7LOtD9wyE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4rKkyivME8w?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=4rKkyivME8w?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4rKkyivME8w/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4rKkyivME8w%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4rKkyivME8w&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4rKkyivME8w%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Podcasts",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=3blBpH1OEZA?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=3blBpH1OEZA?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/3blBpH1OEZA/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F3blBpH1OEZA%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D3blBpH1OEZA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F3blBpH1OEZA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=y-p-MRTwang?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=y-p-MRTwang?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/y-p-MRTwang/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fy-p-MRTwang%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dy-p-MRTwang&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fy-p-MRTwang%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=pwC2iRxTIhE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=pwC2iRxTIhE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/pwC2iRxTIhE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FpwC2iRxTIhE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DpwC2iRxTIhE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FpwC2iRxTIhE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Events",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vnItnUz8B-k/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvnItnUz8B-k%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvnItnUz8B-k&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvnItnUz8B-k%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WxZ5qVBoHL4?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=WxZ5qVBoHL4?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WxZ5qVBoHL4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWxZ5qVBoHL4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWxZ5qVBoHL4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWxZ5qVBoHL4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vmaM7T1AQDU/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvmaM7T1AQDU%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvmaM7T1AQDU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvmaM7T1AQDU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Clive Smith",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/weh4_ICBPPo/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fweh4_ICBPPo%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dweh4_ICBPPo&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fweh4_ICBPPo%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              originalUrl: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/1oAFs8UR5ow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F1oAFs8UR5ow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D1oAFs8UR5ow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F1oAFs8UR5ow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Rc5tRN5PId0",
              originalUrl: "https://www.youtube.com/watch?v=Rc5tRN5PId0",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rc5tRN5PId0/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRc5tRN5PId0%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRc5tRN5PId0&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRc5tRN5PId0%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WxZ5qVBoHL4",
              originalUrl: "https://www.youtube.com/watch?v=WxZ5qVBoHL4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WxZ5qVBoHL4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWxZ5qVBoHL4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWxZ5qVBoHL4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWxZ5qVBoHL4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=q8e5T5Fc_jA",
              originalUrl: "https://www.youtube.com/watch?v=q8e5T5Fc_jA",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/q8e5T5Fc_jA/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fq8e5T5Fc_jA%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dq8e5T5Fc_jA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fq8e5T5Fc_jA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=qVp32goZHuM",
              originalUrl: "https://www.youtube.com/watch?v=qVp32goZHuM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/qVp32goZHuM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqVp32goZHuM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqVp32goZHuM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqVp32goZHuM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "TCM Series",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=TxHJ0IyKZ2Q?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=TxHJ0IyKZ2Q?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/TxHJ0IyKZ2Q/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FTxHJ0IyKZ2Q%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DTxHJ0IyKZ2Q&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FTxHJ0IyKZ2Q%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4bbEGxQgzSE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=4bbEGxQgzSE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4bbEGxQgzSE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4bbEGxQgzSE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4bbEGxQgzSE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4bbEGxQgzSE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Documentaries",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/eOnDBOC5TxQ/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FeOnDBOC5TxQ%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DeOnDBOC5TxQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FeOnDBOC5TxQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/weh4_ICBPPo/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fweh4_ICBPPo%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dweh4_ICBPPo&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fweh4_ICBPPo%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              originalUrl: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/1oAFs8UR5ow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F1oAFs8UR5ow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D1oAFs8UR5ow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F1oAFs8UR5ow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },

            {
              url: "https://www.youtube.com/watch?v=qVp32goZHuM",
              originalUrl: "https://www.youtube.com/watch?v=qVp32goZHuM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/qVp32goZHuM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqVp32goZHuM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqVp32goZHuM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqVp32goZHuM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              originalUrl: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/fkd76_0JnZY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Ffkd76_0JnZY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dfkd76_0JnZY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Ffkd76_0JnZY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              originalUrl: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/ZtlySlf0KC4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZtlySlf0KC4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZtlySlf0KC4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZtlySlf0KC4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/zp7LOtD9wyE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fzp7LOtD9wyE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dzp7LOtD9wyE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fzp7LOtD9wyE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Dr Fozia",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              originalUrl: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/fkd76_0JnZY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Ffkd76_0JnZY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dfkd76_0JnZY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Ffkd76_0JnZY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/eOnDBOC5TxQ/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FeOnDBOC5TxQ%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DeOnDBOC5TxQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FeOnDBOC5TxQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              originalUrl: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/ZtlySlf0KC4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZtlySlf0KC4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZtlySlf0KC4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZtlySlf0KC4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=O6KaSYJg-KY",
              originalUrl: "https://www.youtube.com/watch?v=O6KaSYJg-KY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/O6KaSYJg-KY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FO6KaSYJg-KY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DO6KaSYJg-KY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FO6KaSYJg-KY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              originalUrl: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/EguvYXEI0tM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEguvYXEI0tM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEguvYXEI0tM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEguvYXEI0tM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WBDC_ZZiZpw",
              originalUrl: "https://www.youtube.com/watch?v=WBDC_ZZiZpw",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WBDC_ZZiZpw/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWBDC_ZZiZpw%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWBDC_ZZiZpw&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWBDC_ZZiZpw%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=GdLgb05uYNI",
              originalUrl: "https://www.youtube.com/watch?v=GdLgb05uYNI",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/GdLgb05uYNI/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FGdLgb05uYNI%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DGdLgb05uYNI&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FGdLgb05uYNI%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=r-eI7umWBc8",
              originalUrl: "https://www.youtube.com/watch?v=r-eI7umWBc8",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/r-eI7umWBc8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fr-eI7umWBc8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dr-eI7umWBc8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fr-eI7umWBc8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              originalUrl: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4RF5mjMGm7g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4RF5mjMGm7g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4RF5mjMGm7g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4RF5mjMGm7g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=XX4fBhduF2c",
              originalUrl: "https://www.youtube.com/watch?v=XX4fBhduF2c",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/XX4fBhduF2c/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FXX4fBhduF2c%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DXX4fBhduF2c&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FXX4fBhduF2c%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=7TNCxMkkOow",
              originalUrl: "https://www.youtube.com/watch?v=7TNCxMkkOow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/7TNCxMkkOow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7TNCxMkkOow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7TNCxMkkOow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F7TNCxMkkOow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "News Channels",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=fyFw7lDS_Ys?autoplay=1",
              originalUrl:
                "https://www.youtube.com/watch?v=fyFw7lDS_Ys?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "http://i.ytimg.com/vi/fyFw7lDS_Ys/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FfyFw7lDS_Ys%3Fautoplay%3D1&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DfyFw7lDS_Ys&image=http%3A%2F%2Fi.ytimg.com%2Fvi%2FfyFw7lDS_Ys%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              originalUrl: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/EguvYXEI0tM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEguvYXEI0tM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEguvYXEI0tM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEguvYXEI0tM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=wWWa9MRIV50",
              originalUrl: "https://www.youtube.com/watch?v=wWWa9MRIV50",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/wWWa9MRIV50/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FwWWa9MRIV50%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DwWWa9MRIV50&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FwWWa9MRIV50%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              originalUrl: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4RF5mjMGm7g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4RF5mjMGm7g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4RF5mjMGm7g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4RF5mjMGm7g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Imran Khan",
        },
      ],
    };
  },
  mounted() {
    this.videoData.forEach((el, ind) => {
      el.items.forEach((elem, index) => {
        let script = document.createElement("script");
        script.type = "application/json";
        script.classList.add("w-json");

        let newEl = {
          items: [elem],
          group: el.group,
        };
        script.text = JSON.stringify(newEl);

        let target = document.getElementById(`video-${ind}-${index}`);
        target.parentNode.insertBefore(script, target.nextSibling);
      });
    });

    window.addEventListener("popstate", function (event) {
      const cross = document.querySelector(".w-lightbox-close");
      if (cross) {
        cross.click();
      }
    });
  },
  methods: {
    setBox() {
      window.history.pushState(null, null, window.location.pathname);
    },
  },
};
</script>
