<template>
  <section class="hero-stack wf-section">
    <div class="container-7">
      <div v-if="loading" class="hero-wrapper-two custom-loader-wrapper">
        <div v-if="NotFound">
          <h1 style="margin: 0">No News Found</h1>
        </div>
        <div v-else class="custom-loader"></div>
      </div>
      <div class="hero-wrapper-two" v-if="this.$route.query.id && !loading">
        <img
          v-if="obj.cover"
          :src="obj.cover"
          loading="lazy"
          sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 92vw, (max-width: 1439px) 85vw, 1140px"
          alt=""
          class="hero-image shadow-two"
        />
        <h2 class="newsheading">
          {{ obj.title }}
        </h2>
        <h5 class="heading">
          By {{ obj.author }} ,
          {{
            new Date(obj.date).toLocaleDateString("en-UK", {
              year: "numeric",
              day: "numeric",
              month: "short",
            })
          }}
        </h5>
        <p v-html="obj.content" class="newscontent"></p>
      </div>

      <div class="hero-wrapper-two" v-if="!this.$route.query.id">
        <img
          src="images/causes_detail.jpg"
          loading="lazy"
          sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 92vw, (max-width: 1439px) 85vw, 1140px"
          srcset="
            images/causes_detail-p-500.jpg  500w,
            images/causes_detail-p-800.jpg  800w,
            images/causes_detail.jpg       1020w
          "
          alt=""
          class="hero-image shadow-two"
        />
        <h2 class="newsheading">Heart gives happy Rise Of Global Charity Funds</h2>
        <h5 class="heading">By Welson Stevon, October, 30, 2017</h5>
        <p class="newscontent">
          Lorem ipsum dolor sit amet, denique delectus vis in. Est augue convenire et, sit ponderum eleifend corrumpit
          in. Quem meliore ancillae nam et. Vix mucius sententiae ei. Graece perfecto adolescens te usu, ut mel velit
          feugiat, cu est dicant intellegam. Ceteros adolescens sadipscing at vim, sale invenire necessitatibus ne est.
          Ei vis fugit alterum oportere. In vis summo malorum consequuntur. Est augue convenire et, sit ponderum
          eleifend corrumpit in. Quem meliore ancillae nam et. Vix mucius sententiae ei. Graece perfecto adolescens te
          usu, ut mel velit feugiat, cu est dicant intellegam. Ceteros adolescens sadipscing at vim, sale invenire
          necessitatibus ne est. Ei vis fugit alterum oportere. In vis summo malorum consequuntur.Lorem ipsum dolor sit
          amet, denique delectus vis in. Est augue convenire et, sit ponderum eleifend corrumpit in. Quem meliore
          ancillae nam et. Vix mucius sententiae ei. Graece perfecto adolescens te usu, ut mel velit feugiat, cu est
          dicant intellegam. Ceteros adolescens sadipscing at vim, sale invenire necessitatibus ne est. Ei vis fugit
          alterum oportere. In vis summo malorum consequuntur. Est augue convenire et, sit ponderum eleifend corrumpit
          in. Quem meliore ancillae nam et. Vix mucius sententiae ei. Graece perfecto adolescens te usu, ut mel velit
          feugiat, cu est dicant intellegam. Ceteros adolescens sadipscing at vim, sale invenire necessitatibus ne est.
          Ei vis fugit alterum oportere. In vis summo malorum consequuntur.<br />Lorem ipsum dolor sit amet, denique
          delectus vis in. Est augue convenire et, sit ponderum eleifend corrumpit in. Quem meliore ancillae nam et. Vix
          mucius sententiae ei. Graece perfecto adolescens te usu, ut mel velit feugiat, cu est dicant intellegam.
          Ceteros adolescens sadipscing at vim, sale invenire necessitatibus ne est. Ei vis fugit alterum oportere. In
          vis summo malorum consequuntur. Est augue convenire et, sit ponderum eleifend corrumpit in. Quem meliore
          ancillae nam et. Vix mucius sententiae ei. Graece perfecto adolescens te usu, ut mel velit feugiat, cu est
          dicant intellegam. Ceteros adolescens sadipscing at vim, sale invenire necessitatibus ne est. Ei vis fugit
          alterum oportere. In vis summo malorum consequuntur.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "SingleNews",
  data() {
    return {
      obj: {},
      loading: false,
      NotFound: false,
    };
  },
  created() {
    // console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/News/" + this.$route.query.id,
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          if (response.data.data == "NotFound") {
            this.NotFound = true;
          } else {
            this.obj = response.data.data;
            this.loading = false;
          }
        })
        .catch(function (error) {
          // console.log(error);
          this.NotFound = true;
          // this.loading = false;
        });
    },
  },
};
</script>
<style></style>
